import React from 'react';
import classNames from 'classnames';

import { useConfiguration } from '../../context/configurationContext';

import { FormattedMessage } from '../../util/reactIntl';
import { displayPrice } from '../../util/configHelpers';
import { lazyLoadWithDimensions } from '../../util/uiHelpers';
import { formatMoney } from '../../util/currency';
import { isBookingProcessAlias } from '../../transactions/transaction';

import { AspectRatioWrapper, IconVerified, NamedLink, Button, ResponsiveImage } from '../../components';
import { FaInstagram, FaYoutube, FaFacebook, FaWhatsapp, FaMapMarkerAlt, FaLinkedin } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';

import css from './ListingCard.module.css';

const formatFollowers = (count) => {
  if (count >= 1000000) {
    return (count / 1000000).toFixed(1) + 'M';
  } else if (count >= 1000) {
    return (count / 1000).toFixed(1) + 'K';
  }
  return count;
};

const priceData = (price, currency, intl) => {
  if (price && price.currency === currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'UserCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'UserCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

const LazyImage = lazyLoadWithDimensions(ResponsiveImage, { loadAfterInitialRendering: 3000 });

const PriceMaybe = props => {
  const { price, publicData, config, intl } = props;
  const { listingType } = publicData || {};
  const validListingTypes = config.listing.listingTypes;
  const foundListingTypeConfig = validListingTypes.find(conf => conf.listingType === listingType);
  const showPrice = displayPrice(foundListingTypeConfig);
  if (!showPrice && price) {
    return null;
  }

  const isBookable = isBookingProcessAlias(publicData?.transactionProcessAlias);
  const { formattedPrice, priceTitle } = priceData(price, config.currency, intl);
  return (
    <div className={css.price}>
      <div className={css.priceValue} title={priceTitle}>
        {formattedPrice}
      </div>
      {isBookable ? (
        <div className={css.perUnit}>
          <FormattedMessage id="UserCard.perUnit" values={{ unitType: publicData?.unitType }} />
        </div>
      ) : null}
    </div>
  );
};

/**
 * UserCard
 *
 * @component
 * @param {Object} props
 * @param {string?} props.className add more style rules in addition to component's own css.root
 * @param {string?} props.rootClassName overwrite components own css.root
 * @param {Object} props.listing API entity: listing or ownListing
 * @param {string?} props.renderSizes for img/srcset
 * @param {boolean?} props.showAuthorInfo
 * @returns {JSX.Element} listing card to be used in search result panel etc.
 */
export const UserCard = props => {
  const config = useConfiguration();

  const {
    className,
    rootClassName,
    renderSizes,
    listingCategories,
    listingTypes,
    user,
    location,
    onUpdateCurrentUser,
    updateCurrentUserInProgress,
    currentUser,
    creatorNiche,
    setOpenWishlistSelector,
    selectedCategory
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const id = user.id.uuid;
  const firstImage = user?.profileImage ? user.profileImage : null;

  const {
    aspectWidth = 3,
    aspectHeight = 2,
    variantPrefix = 'square-small2x',
  } = config.layout.userImage || {};

  const variants = firstImage
    ? Object.keys(firstImage?.attributes?.variants).filter(k => k.startsWith(variantPrefix))
    : [];

  const isOnWishlist = currentUser?.attributes.profile.publicData.wishlists?.some(wishlist => wishlist.users.includes(user.id.uuid));

  return (
    <div className={classes}>
      <AspectRatioWrapper
        className={css.userAspectRatioWrapper}
        width={aspectWidth}
        height={aspectHeight}
      // {...setActivePropsMaybe}
      >
        <LazyImage
          rootClassName={css.userRootForImage}
          alt={"title"}
          image={firstImage}
          variants={variants}
          sizes={renderSizes}
        />
                {user?.attributes?.profile?.publicData?.verified_professionalCreator && <span className={css.professionalCreator}><IconVerified className={css.whiteVerifiedIcon} /> Pro</span>}
        <div className={css.nameLocation}>
            <p className={css.name}>
              {user.attributes.profile.displayName}{user.attributes.profile.publicData.user_verified && <IconVerified />}
            </p>
            {user?.attributes?.profile?.publicData?.location?.selectedPlace?.address && (
              <div className={css.location}>
                <FaMapMarkerAlt className={css.locationIcon} /> 
                {user?.attributes?.profile?.publicData?.location?.selectedPlace?.address}
              </div>
            )}
          </div>
      </AspectRatioWrapper>
      <div className={css.userInfo}>
        <div className={css.userMainInfo}>
          <div className={css.socialMedia}>
            {user && user.attributes?.profile?.publicData?.user_instagram_url && (
              <div className={css.socialItem}>
                <FaInstagram className={css.instagramIcon} />
                {user.attributes.profile.publicData.user_instagram_followers && (
                  <span>
                    {formatFollowers(user.attributes.profile.publicData.user_instagram_followers)}
                  </span>
                )}
                {user.attributes?.profile?.publicData.instagram_blue_badge?.split('_')?.includes('yes') && (
                  <IconVerified className={css.socialVerified} />
                )}
              </div>
            )}
            {user && user.attributes?.profile?.publicData?.user_youtube_url && (
              <div className={css.socialItem}>
                <FaYoutube className={css.youtubeIcon} />
                {user.attributes.profile.publicData.user_youtube_subscribers && (
                  <span>
                    {formatFollowers(user.attributes.profile.publicData.user_youtube_subscribers)}
                  </span>
                )}
              {!user.attributes?.profile?.publicData.youtube_play_button && (
                  <IconVerified className={css.greenVerified} />
                )}
              </div>
            )}
            {user && user.attributes?.profile?.publicData?.user_x_handle && (
              <div className={css.socialItem}>
                <FaXTwitter className={css.twitterIcon} />
                {user.attributes.profile.publicData.user_x_followers && (
                  <span>
                    {formatFollowers(user.attributes.profile.publicData.user_x_followers)}
                  </span>
                )}
                {user.attributes?.profile?.publicData.x_verified?.split('_')?.includes('yes') && (
                  <IconVerified className={css.socialVerified} />
                )}
              </div>
            )}
            {user && user.attributes?.profile?.publicData?.user_facebook_url && (
              <div className={css.socialItem}>
                <FaFacebook className={css.facebookIcon} />
                {user.attributes.profile.publicData.user_facebook_followers && (
                  <span>
                    {formatFollowers(user.attributes.profile.publicData.user_facebook_followers)}
                  </span>
                )}
                {user.attributes?.profile?.publicData.facebook_verified?.split('_')?.includes('yes') && (
                  <IconVerified className={css.socialVerified} />
                )}
              </div>
            )}
            {user && user.attributes?.profile?.publicData?.user_whatsapchannel_url && (
              <div className={css.socialItem}>
                <FaWhatsapp className={css.whatsappIcon} />
                {user.attributes.profile.publicData.user_whatsapp_followers && (
                  <span>
                    {formatFollowers(user.attributes.profile.publicData.user_whatsapp_followers)}
                  </span>
                )}
                {user.attributes?.profile?.publicData.whatsapp_badge?.split('_')?.includes('yes') && (
                  <IconVerified className={css.greenVerified} />
                )}
              </div>
            )}
            {user && user.attributes?.profile?.publicData?.user_linkedin_url && (
              <div className={css.socialItem}>
                <FaLinkedin className={css.linkedinIcon} />
                {user.attributes.profile.publicData.user_linkedin_followers && (
                  <span>
                    {formatFollowers(user.attributes.profile.publicData.user_linkedin_followers)}
                  </span>
                )}
              </div>
            )}
          </div>
          {user?.attributes?.profile?.publicData?.categories?.length > 0 && (
          <div className={css.tags}>
            {user?.attributes?.profile?.publicData?.listingTypes?.map((tag, index) => (
              <span
                key={index}
                className={css.tag}
              >
                {tag === "collab_availability" ? "Creator" : "Marketer"}
              </span>
            ))}
            {user?.attributes?.profile?.publicData?.categories?.map((tag, index) => (
              <span
                key={index}
                className={css.tag}
              >
                {listingCategories?.find(c => c.id === tag)?.name || tag}
              </span>
            ))}
        </div>
        )}
        </div>
        
        <p className={css.niche}>
            <b>Niche: </b>{creatorNiche}
          </p>
        <div className={css.buttons}>
          
        <NamedLink
          name="ProfilePage"
          params={{ id: user.id.uuid }}
          className={css.viewProfileButton}
        >
          View Profile
        </NamedLink>
        {((!selectedCategory && !isOnWishlist)) && (
        <Button 
          type="button"
          rootClassName={classNames(css.collabButton, css.notFavourite)}
          disabled={updateCurrentUserInProgress}
          onClick={() => {
            setOpenWishlistSelector(user.id.uuid);
          }}
        >
          {'Add to favourite'}
        </Button>
        )}
        {selectedCategory && (
          <Button
            type="button"
            rootClassName={css.collabButton}
            disabled={updateCurrentUserInProgress}
            onClick={() => {
              const currentWhishlist = currentUser?.attributes.profile.publicData.wishlists || [];
              const newSelectedCategory = currentWhishlist.find(wishlist => wishlist.name === selectedCategory.name);
              const newWhishlist = newSelectedCategory.users.filter(u => u !== user.id.uuid);
  
              if (selectedCategory !== "") {
                onUpdateCurrentUser({ publicData: { wishlists: currentWhishlist?.map(wishlist => wishlist.name === selectedCategory.name ? { ...wishlist, users: newWhishlist } : wishlist) } });
              }
            }}
          >
            Remove from wishlist
          </Button>
        )}
        {(!selectedCategory && isOnWishlist) && <Button
          type="button"
          rootClassName={css.collabButton}
          disabled={updateCurrentUserInProgress}
          onClick={() => {
            const updatedWishlists = currentUser?.attributes.profile.publicData.wishlists?.map(wishlist => {
              if (wishlist.users.includes(user.id.uuid)) {
                return { ...wishlist, users: wishlist.users.filter(id => id !== user.id.uuid) };
              }
              return wishlist;
            });
            onUpdateCurrentUser({ publicData: { wishlists: updatedWishlists } });
          }
          }
        >
          {'Remove from favourite'}
        </Button>}
      </div>
      </div>
    </div>
  );
};

export default UserCard;
